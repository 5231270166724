<script>
import StoreRGPDForm from '@app/components/StoreRGPDForm.vue';

export default {
  components: {
    StoreRGPDForm,
  },
  head: {
    title: 'Communication - Paramètres',
  },
};
</script>

<template>
  <div>
    <StoreRGPDForm />
  </div>
</template>
